/* #app {
  height: 100%;
} */
html,
/* body {
  position: relative;
  height: 100%;
} */

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}



/* .swiper {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;

} */

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
  max-height: 100%;
  border-radius: 5px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  /* aspect-ratio:16/9 !important; */
  object-fit: cover;
} 


.swiper-slide video {
  display: block;
  width: 100px;
  height: 100px;
  object-fit: cover;
  aspect-ratio:16/9 !important;
}



.swiper {

  aspect-ratio:16/9 !important;
  overflow: hidden;
  border-radius: 5px;
}
