.simple-keyboard {
    width: 100%;
    height:100%;
    background-color: rgb(0, 0, 0);

  }

  input {
    width: 100%;
    height: 2rem;
    padding: 10px;
    font-size: 20px;
    color:navy;
    border: none;
    box-sizing: border-box;
  }