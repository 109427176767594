.area {
    background: white;  
    width: 100%;
    height: 100vh;
}

.circles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

}

.circles li {
    position: absolute;
    display: block;
    list-style: none;

    animation: animate 25s linear infinite;
    bottom: -150px;
}

@keyframes animate {
    0% {
        transform: translateY(0) rotate(0deg);
        opacity: .2;
        border-radius: 0;
    }
    100% {
        transform: translateY(-3000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}




/* Pulse Animations */
/* Your original keyframe animation */
@keyframes pulse-kefe91hg {
    100% {
        box-shadow: 0 0 0 150.4px #0000;
    }
}

/* Mobile media query */
@media (max-width: 768px) { /* Adjust the max-width value as needed */
    /* Override the original animation for mobile */
    .pulse {
        animation-name: pulse-kefe91hg-mobile;
    }
}

/* Define a new keyframe animation for mobile */
@keyframes pulse-kefe91hg-mobile {
    100% {
        box-shadow: 0 0 0 50px rgba(118, 195, 117, 0.5); /* Adjust the box-shadow for mobile */
    }
}

/* Your pulse class styles */
.pulse {
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background: #76c375;
    box-shadow: 0 0 0 0 rgba(118, 195, 117, 0.5);
    animation: pulse-kefe91hg 3s infinite linear;
    position: relative;
    
}
/* Media query for mobile devices (max-width: 767px) */
@media (max-width: 767px) {
    /* Your mobile styles here */
    .pulse {
        width: 5vw;
    height: 5vw;
    border-radius: 50%;
    background: #76c375;
    box-shadow: 0 0 0 0 rgba(118, 195, 117, 0.5);
    animation: pulse-kefe91hg-mobile 3s infinite linear;
    position: relative;
    }
}

.pulse:before,
.pulse:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 rgba(118, 195, 117, 0.5);
    animation: inherit;
    animation-delay: -0.6s;
}

.pulse:after {
    animation-delay: -1.2s;
}

/* Mobile media query */

 .font-load{
    color: #1F1F1F;
    width:70vw;
    text-align: center;
    font-family: Raleway;
    font-size: 8vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
 }